<template>
  <div class="building-subscribe">
    <navbar title="预约看房" />
    <div class="subscribe-box">
      <van-cell-group>
        <van-field v-model="build" label="意向楼盘" readonly input-align="right" placeholder="请输入物业人员账号" />
        <van-field v-model="name" @input="nameFocus" label="姓名" input-align="right" placeholder="请输入预约姓名" />
        <van-field v-model="phone" label="手机号" type="number" input-align="right" placeholder="请输入预约手机号" />
        <van-field v-model="message" rows="4" autosize type="textarea" placeholder="预约看房留言" />
      </van-cell-group>

      <div class="bind-btn" @click="submitForm">确定</div>
    </div>

  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";

export default {
  name: "buildingSubscribe",
  data() {
    return {
      build: "",
      name: "",
      phone: "",
      message: "",
    };
  },
  methods: {
    submitForm() {},
    nameFocus(value) {
      this.name = value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, "");
    },
  },
  components: {
    navbar,
  },
};
</script>
<style lang="less" scoped>
.building-subscribe {
  .subscribe-box {
    padding: 20px;
    /deep/.van-cell-group {
      margin-bottom: 30px;
      .van-cell {
        background: #f6f6f6;
        padding: 8px 0;
        color: #535353;
        font-size: 17px;
        border: unset;
        border-bottom: 1px solid #ddd;
        &:last-child {
          border-bottom: 0;
        }
        input {
          font-size: 16px;
        }
        textarea {
          background-color: #fff;
          padding: 8px;
          color: #666;
          font-size: 15px;
        }
      }
    }
    .bind-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 5px;
      background: #00d3c4;
      font-size: 18px;
      height: 40px;
    }
  }
}
</style>